import React, { useState, useEffect } from 'react'
import '../css/styles.scss'

import { Footer } from '../components/footer'
import { useLang } from '../hooks/useLang'

export const Layout = ({ children }) => {
	const lang = useLang()
	const [hideHeader, setHideHeader] = useState(false)
	const [myWindow, setMyWindow] = useState({ location: { hash: '' } })

	useEffect(() => {
		setMyWindow(window)
	}, [])

	useEffect(() => {
		if (
			myWindow.location.pathname === '/' && (
				myWindow.location.hash === '' ||
				myWindow.location.hash === '#firstview' ||
				myWindow.location.hash === '#title')) {
			setHideHeader(true)
		} else {
			setHideHeader(false)
		}
	}, [myWindow, myWindow.location.hash])

	const handleLangChange = (lang) => {
		localStorage.setItem('lang', lang)
		window.location.reload()
	}

	return (
		<>
			<div className={`header ${hideHeader ? 'hide' : ''}`}>
				<div className="lang">
					<a className={`${lang === 'ja' ? 'active' : ''}`} href="javascript:void(0)" onClick={() => handleLangChange('ja')}>JP</a>
					<a className={`${lang === 'en' ? 'active' : ''}`} href="javascript:void(0)" onClick={() => handleLangChange('en')}>EN</a>
				</div>
				<ul>
					<li><a href="/#introduction">ABOUT</a></li>
					<li><a href="/contact">CONTACT</a></li>
				</ul>
			</div>
			{children}
			<Footer />
		</>
	)
}
