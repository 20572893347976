import React from 'react'
import { Image } from '../components/image'

export const Footer = () => {
	return (
		<div className="footer">
			<div className="logo">
				<a href="http://witdesignplaats.com/" target="_blank" rel="noreferrer">
					<Image filename="footer_wit.png" width={58} height={18} alt="footer_wit" />
				</a>
			</div>
			<div className="copyright">
				<div>© Copyright {new Date().getFullYear()}. powered by INSPIRATION CULT. <br className="sp" />InspirationCult theme by WIT inc.</div>
			</div>
		</div>
	)
}
