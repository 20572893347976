import { useState, useEffect } from 'react'

export const useLang = () => {
	const [lang, setLang] = useState('')

	useEffect(() => {
		localStorage.getItem('lang') ? setLang(localStorage.getItem('lang')) : setLang('ja')
	}, [])

	return lang
}
